import React from 'react'
import {Route, Routes} from 'react-router-dom';
import {useSelector} from "react-redux";

import {
    Activity,
    AdminPLAccount,
    ApiSetting,
    SetupBox,
    BotConfig,
    CoinMarket,
    Dashboard,
    Login,
    Manual,
    Market,
    PaidHistory,
    PLAccount,
    Portfolio,
    Prediction,
    ProfitLoss,
    Registration,
    Satistics,
    Trailing,
    UserDashboard,
    UserManagement,
    UserProfit,
    UserStatistics,
    UserAssignManagement
} from 'screens';

import {SideBar, UserSideBar} from './components';
import ProtectedRoute from "./components/ProtectedRoute";
import {useBinanceApi} from "./hooks";

function App() {
    const {user} = useSelector((store) => store.user);

    useBinanceApi(user?.token)


    return (
        <main className='custom-main'>
            {user
                ? ['ADMIN','SUB_ADMIN'].includes(user.role)
                    ? <SideBar role = {user.role} />
                    : <UserSideBar/>
                : null
            }
            <Routes>
                <Route path='/login' element={<Login/>}/>
                <Route path='/registration' element={<Registration/>}/>
                {/*admin Routes*/}
                <Route exact path='/' element={<ProtectedRoute/>}>
                    <Route exact path='/' element={<Dashboard/>}/>
                    <Route path='/activity' element={<Activity/>}/>
                    <Route path='/portfolio' element={<Portfolio/>}/>
                    <Route path='/profit-loss' element={<ProfitLoss/>}/>
                    <Route path='/coin-market' element={<CoinMarket/>}/>
                    <Route path='/auto-rsi' element={<SetupBox/>}/>
                    <Route path='/auto-trailing' element={<Trailing/>}/>
                    <Route path='/manual-bot' element={<Manual/>}/>
                    <Route path='/user-management' element={<UserManagement/>}/>
                    <Route path='/satistics' element={<Satistics/>}/>
                    <Route path='/pl-account' element={<AdminPLAccount/>}/>
                    <Route path='/user-assign-management' element={<UserAssignManagement/>}/>
                    <Route path='/market' element={<Market/>}/>
                </Route>
                {/*User Routes*/}
                <Route exact path='/user' element={<ProtectedRoute/>}>
                    <Route path='/user-profit' element={<UserProfit/>}/>
                    <Route path='/user/bot-config' element={<BotConfig/>}/>
                    <Route path='/user/prediction' element={<Prediction/>}/>
                    <Route path='/user/satistics' element={<UserStatistics/>}/>
                    <Route path='/user/pl-account' element={<PLAccount/>}/>
                    <Route path='/user/paid-history' element={<PaidHistory/>}/>
                    <Route path='/user/dashboard' element={<UserDashboard/>}/>
                    <Route path='/user/api-setting' element={<ApiSetting/>}/>
                    <Route path='/user/market' element={<Market/>}/>
                </Route>
            </Routes>
        </main>
    );
}

export default App;
