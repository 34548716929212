import {Col, Container, Form, Row} from 'react-bootstrap'
import React from "react";
import _ from "lodash";

function Manual({onSubmitHandler, onChangeHandler, onBotStatusHandler, isLoading, data}) {

    const coin = _.get(data, 'coin', '');

    return <>
        <div className="dashboard-main custom-scroll">
            <div className="section">
                <div className="mt-5">
                    <Container fluid>
                        <Form onSubmit={onSubmitHandler}>
                            <Row className="justify-content-center">
                                <Col lg={4} md={6}>
                                    <div className="bot-box">
                                        <h4 className="heading">Configuration</h4>
                                        <Form onSubmit={onSubmitHandler}>
                                            <span>Low Price in ({coin}):</span>
                                            <Form.Group className="mb-3" controlId="low">
                                                <Form.Control className='custom-input'
                                                              name='low'
                                                              type="number"
                                                              placeholder={'Low'}
                                                              value={data?.low}
                                                              onChange={onChangeHandler}
                                                              required
                                                />
                                            </Form.Group>

                                            <span>Up Price in ({coin}):</span>
                                            <Form.Group className="mb-3" controlId="low">
                                                <Form.Control className='custom-input'
                                                              name='up'
                                                              type="number"
                                                              placeholder={'Up Price'}
                                                              value={data?.up}
                                                              onChange={onChangeHandler}
                                                              required
                                                />
                                            </Form.Group>

                                            <span>Investment:</span>
                                            <Form.Group className="mb-3" controlId="low">
                                                <Form.Control className='custom-input'
                                                              type="number"
                                                              name='investment'
                                                              value={data?.investment}
                                                              onChange={onChangeHandler}
                                                              placeholder='Investment Amount (USDT)'
                                                              required
                                                />
                                            </Form.Group>

                                            <span>Take Profit:</span>
                                            <Form.Group className="mb-3" controlId="takeProfit">
                                                <Form.Control className='custom-input'
                                                              type="number"
                                                              name='takeProfit'
                                                              value={data?.takeProfit}
                                                              placeholder='Take Profit Amount'
                                                              onChange={onChangeHandler}
                                                              required
                                                />
                                            </Form.Group>
                                        </Form>
                                        <div className='d-flex my-2'>
                                            <Form.Check
                                                className="custom-switch"
                                                type="switch"
                                                id="custom-switch"
                                                label="Bot Status"
                                                checked={data.isActive}
                                                onChange={onBotStatusHandler}
                                            />
                                        </div>

                                        <button className='custom-btn primary-btn mt-2 w-100' disabled={isLoading} type='submit'>
                                            {isLoading ? 'Submitting' : 'Submit'}
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Container>

                </div>

            </div>

        </div>
    </>
}

export default Manual