import React, {useEffect, useState} from "react"
import {AutoTabs} from "../../components";
import {INDICATORS, OPERATIONS, RISKS} from "../../constants";
import setupBotValidation from "validations/setupBot";
import {toast} from "react-toastify";
import {useMutation} from "react-query";
import {apis} from "../../services";
import {useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import Manual from "./Manual";
import Automatic from "./Automatic";

const getInitialState = (setting, user, isActive = false, coin) => {
    if (setting) {
        const {risk, low, up, indicator = INDICATORS.rsi, operation, time = null, investment, takeProfit = 0} = setting;
        return {up, low, risk, time, isActive, coin, indicator, operation, investment, configured_by: user?.name,takeProfit:takeProfit}
    }
}

function SetupBox(options) {
    const {state} = useLocation();
    const navigate = useNavigate();
    const {user} = useSelector(store => store.user);
    const {balance} = useSelector(store => store.binance);
    const [data, setData] = useState({
        risk: RISKS[0],
        low: 0,
        up: 0,
        time: null,
        indicator: INDICATORS.rsi,
        operation: OPERATIONS.auto,
        configured_by: user?.name,
        stop: true,
        coin: '',
        takeProfit: 0
    });
    const [routeState, setRouteState] = useState(null);

    useEffect(() => {
        setRouteState(state);
    }, []);


    useEffect(() => {
        if (routeState && user) {
            setData(getInitialState(routeState.setting, user, routeState?.isActive, routeState.coin))
        }
    }, [routeState])

    const {mutate: setupBot, isLoading} = useMutation(apis.configureBotSetting, {
        onError: ({message}) => toast.error(message),
        onSuccess: ({data, status}) => {
            if (status === 200) {
                toast.success(data);
                navigate('/activity')
                setData(getInitialState(user))
            }
            // console.log({data, status});
        }
    });

    const onTypeHandler = (key, value) => setData(prevState => ({...prevState, [key]: value}));
    const handleTimeSelect = (time) => setData(prevState => ({...prevState, ['time']: time}));
    const onBotStatusHandler = () => setData(prevState => ({...prevState, isActive: !prevState.isActive}))
    const onChangeHandler = ({target: {name, value}}) => {
        if (name === "investment" && Number(value) > balance)
            return toast.warning(`Investment can not be greater than ${balance}`, {toastId: name})
        setData(prevState => ({...prevState, [name]: value}))
    }

    const onSubmitHandler = (e) => {
        e.preventDefault();

        console.log(data);
        const {error} = setupBotValidation(data);
        if (error) return toast.error(error.details[0].message);

        const user = routeState?.user_id?._id;
        const setting_id = routeState.setting._id;

        setupBot({id: setting_id, body: {...data, user}});
    }

    useEffect(() => {
        if (!state) {
            toast.warn('Access denied.')
            navigate('/activity')
        }
    }, []);

    //UseEffect for reset value when risk type changes
    // useEffect(() => setData(prevState => ({...prevState, low: 0, up: 0, time: null})), [data.risk_type]);

    return <>
        <div className="dashboard-main custom-scroll">
            <div className="section">
                <h3 className="section-title">SETUP BOT</h3>
                <AutoTabs indicator={data.indicator} operation={data.operation} setData={setData}/>
                {
                    data.operation === OPERATIONS.auto
                        ? <Automatic
                            data={data}
                            isLoading={isLoading}
                            onTypeHandler={onTypeHandler}
                            onChangeHandler={onChangeHandler}
                            onSubmitHandler={onSubmitHandler}
                            handleTimeSelect={handleTimeSelect}
                            onBotStatusHandler={onBotStatusHandler}
                        />
                        : <Manual
                            data={data}
                            isLoading={isLoading}
                            onChangeHandler={onChangeHandler}
                            onSubmitHandler={onSubmitHandler}
                            onBotStatusHandler={onBotStatusHandler}
                        />
                }

            </div>
        </div>
    </>
}

export default SetupBox