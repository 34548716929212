import {createSlice} from "@reduxjs/toolkit";

const binanceSlice = createSlice({
    name: 'binance',
    initialState: {
        data: [],
        balance: 0.0000
    },
    reducers: {
        setBinanceValues: (state, action) => {
            state.data = action.payload
        },
        clearBinanceValues: (state) => {
            state = null;
        },
        setBalance: (state, action) => {
            state.balance = action.payload;
        }
    }
});

export const {setBinanceValues, clearBinanceValues,setBalance} = binanceSlice.actions
export default binanceSlice.reducer;
