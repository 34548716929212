import {apis} from "../services"
import {useQuery} from "react-query";
import {useNavigate} from "react-router-dom";
import {Loader} from "../components";
import {ActivityRecord} from "../components/admin";

function Activity() {
    const navigate = useNavigate();

    const {data, isLoading} = useQuery(['getAllBots'], () => apis.allBots());
    const users = data?.data || [];

    const handleSetupBot = (data) => navigate('/auto-rsi', {state: data});

    return <>

        <div className="dashboard-main custom-scroll">
            {
                isLoading
                    ? <Loader variant='light' style={{minHeight: '100vh'}}/>
                    : users?.length > 0
                        ? <div className="section">
                            <table className="table table1">
                                <thead>
                                <tr>
                                    <th>User ID</th>
                                    <th>User Name</th>
                                    <th>Investment</th>
                                    <th>Profit</th>
                                    <th>Loss</th>
                                    <th>Bot Status</th>
                                    <th>Configuration</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    users.map((user, i) => <ActivityRecord key={i} id={i + 1}
                                                                           handleSetupBot={handleSetupBot} {...user}  />)
                                }
                                </tbody>
                            </table>
                        </div>
                        : <div className="custom-user"><p> No User Bot Activity found.</p></div>
            }
        </div>
    </>
}

export default Activity