import {Col, Container, Row} from "react-bootstrap";
import {RISKS} from "../../constants";
import {SetupBotRiskBox} from "../../components/admin";
import React from "react";

const Automatic = ({
                       handleTimeSelect,
                       data,
                       isLoading,
                       onChangeHandler,
                       onSubmitHandler,
                       onTypeHandler,
                       onBotStatusHandler
                   }) => {

    return (
        <>
            <div className="mt-5">
                <Container fluid>
                    <Row className="gy-5">
                        {RISKS.map((risk, index) =>
                            <SetupBotRiskBox
                                key={index}
                                risk={risk}
                                handleTimeSelect={handleTimeSelect}
                                data={data}
                                isLoading={isLoading}
                                onChangeHandler={onChangeHandler}
                                onSubmitHandler={onSubmitHandler}
                                onTypeHandler={onTypeHandler}
                                onBotStatusHandler={onBotStatusHandler}
                            />)}
                    </Row>
                </Container>

            </div>
            <div className="risk-main custom-scroll">
                <Container fluid>
                    <Row>
                        <Col lg={4}>
                            <div className="risk-inner ">
                                <h4 className="heading">Low Risk</h4>
                                <div className="inner-main">
                                    <div className="inner">
                                        <span>Buy</span>
                                        <h4>00000</h4>
                                        <h4>00000</h4>
                                        <h4>00000</h4>

                                    </div>
                                    <div className="inner">
                                        <span>Sell</span>
                                        <h4>00000</h4>
                                        <h4>00000</h4>
                                        <h4>00000</h4>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="risk-inner ">
                                <h4 className="heading">Moderate Risk</h4>
                                <div className="inner-main">
                                    <div className="inner">
                                        <span>Buy</span>
                                        <h4>00000</h4>
                                        <h4>00000</h4>
                                        <h4>00000</h4>

                                    </div>
                                    <div className="inner">
                                        <span>Sell</span>
                                        <h4>00000</h4>
                                        <h4>00000</h4>
                                        <h4>00000</h4>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="risk-inner ">
                                <h4 className="heading">High Risk</h4>
                                <div className="inner-main">
                                    <div className="inner">
                                        <span>Buy</span>
                                        <h4>00000</h4>
                                        <h4>00000</h4>
                                        <h4>00000</h4>

                                    </div>
                                    <div className="inner">
                                        <span>Sell</span>
                                        <h4>00000</h4>
                                        <h4>00000</h4>
                                        <h4>00000</h4>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
};


export default Automatic;