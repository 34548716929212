import {Dropdown, Form} from "react-bootstrap";

const ActivityRecord = (props) => {
    const {user, investment, profit, loss, handleSetupBot, id, _id, setting, isActive,coin} = props;

    console.log({isActive})

    return (
        <tr className={setting['configured_by'] === 'not configured' ? 'active-row' : '' }>
            <td>
                <div className="td-bg letf-radius"> {id} </div>
            </td>
            <td>
                <div className="td-bg">{user?.name}</div>
            </td>
            <td>
                <div className="td-bg">{setting?.investment}</div>
            </td>
            <td>
                <div className="td-bg"><span className="text-green">{profit}</span></div>
            </td>
            <td>
                <div className="td-bg"><span className="text-red">{loss}</span></div>
            </td>
            <td>
                <div className="td-bg">
                    <Form.Check
                        className="custom-switch"
                        type="switch"
                        id="custom-switch"
                        label=""
                        checked={isActive}
                        // onChange={isOn ? handleShow : ()=>setIsOn(true)}
                    />
                </div>
            </td>
            <td>
                <div className="td-bg">
                    {setting?.configured_by}
                </div>
            </td>
            <td>
                <div className="td-bg right-radius">
                    <Dropdown className="boot-custom-dropdown">
                        <Dropdown.Toggle id="dropdown-basic">
                            <i className="fa-solid fa-ellipsis"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleSetupBot({_id, setting, user, isActive,coin})}>
                                Setup Bot
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </td>
        </tr>
    )
};

export default ActivityRecord