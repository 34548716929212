import axios from "axios";
import {LIVE_SERVER, LOCAL_SERVER} from "../constants";


const createBackendServer = (baseURL) => {
    axios.defaults.withCredentials = true;


    const api = axios.create({
        baseURL: `${baseURL}/api`,
        headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": '*'
        },
        timeout: 60 * 1000,
    });

    //Interceptor
    api.interceptors.response.use(
        (response) => response,
        (error) => {
            const message = error?.response?.data;
            error.message = message ?? error.message
            /*if(error?.response?.data?.errors)
                error.errors = error?.response?.data?.errors;*/
            return Promise.reject(error)
        })


    const authenticate = body => api.post('auth', body);
    const registration = body => api.post('users', body);
    const saveApiKeys = body => api.post('users/api_keys', body);
    const getApiKeys = () => api.get('users/api_keys');

    // Bots Requests
    const allBots = () => api.get('bots');
    const createBot = body => api.post('bots', body);
    const userBots = ({queryKey}) => api.get(`bots/${queryKey[0]}`);
    const userOrdersBot = ({queryKey}) => api.get(`bots/${queryKey[0]}-orders`)

    // PredictionModel Requests
    const getPredictions = ({queryKey}) => api.get(`prediction?currency=${queryKey[1]}`);
    const paidHistory = ({queryKey}) => api.get(`profit_loss/paid_history?coin=${queryKey[0]}`);
    const userDashboard = ({queryKey}) => api.get(`profit_loss/user_dashboard?exchange=${queryKey[0]}`)
    const profitLossAccountDetails = ({queryKey}) => api.get(`profit_loss/account?currency=${queryKey[0]}`);

    const binance = () => api.get('binance')
    const balance = () => api.get('binance/balance')


    // ADMIN ROUTES
    const allUsers = () => api.get('users/all');
    const allSubAdmin = () => api.get('admin/sub_admin');
    const usersPortfolio = () => api.get('admin/user_portfolio');
    const userAssignAdmin = (body) => api.post(`admin/sub_admin_user/`, body);
    const configureBotSetting = ({id, body}) => api.post(`admin/update_bot_setup/${id}`, body);
    const profitLossStatisticsAdmin = () => api.get(`admin/profit_loss/statistics`);
    const profitLossAccountDetailsAdmin = (type) => api.get(`admin/profit_loss/dashboard?exchange=${type}`);


    //Returning all the API
    return {
        allBots,
        binance,
        balance,
        userBots,
        allUsers,
        createBot,
        getApiKeys,
        saveApiKeys,
        paidHistory,
        allSubAdmin,
        authenticate,
        registration,
        userDashboard,
        userOrdersBot,
        usersPortfolio,
        getPredictions,
        userAssignAdmin,
        configureBotSetting,
        profitLossAccountDetails,
        profitLossStatisticsAdmin,
        profitLossAccountDetailsAdmin,
    };
};

const apis = process.env.NODE_ENV === "development"
    ? createBackendServer(LOCAL_SERVER)
    : createBackendServer(LIVE_SERVER);

export default apis;
