import {useEffect} from "react";
import {LIVE_SERVER, LOCAL_SERVER, SOCKET_EVENTS} from "../constants";
import {useDispatch} from "react-redux";
import {setBalance, setBinanceValues} from "../redux/slices/binance.slice";
import io from "socket.io-client";

const useBinanceApi = (token = null) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (token) {
            const SERVER = process.env.NODE_ENV === "development"
                ? LOCAL_SERVER
                : LIVE_SERVER;
            const socket = io(SERVER);

            socket.emit(SOCKET_EVENTS.HIT_BINANCE_API, socket.id);
            socket.on(SOCKET_EVENTS.SEND_BINANCE_API_DATA,
                (data) => {
                    const {balance, ...restData} = data;
                    dispatch(setBinanceValues(restData));
                    dispatch(setBalance(balance));
                });

            setInterval(() => {
                socket.emit(SOCKET_EVENTS.HIT_BINANCE_API, socket.id);
                socket.on(SOCKET_EVENTS.SEND_BINANCE_API_DATA,
                    (data) => {
                        const {balance, ...restData} = data;
                        dispatch(setBinanceValues(restData));
                        dispatch(setBalance(balance));
                    });
            }, 5000)
        }
    }, [dispatch, token]);
};


export default useBinanceApi
