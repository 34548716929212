export const RISKS = ['LOW', 'MODERATE', 'HIGH'];
export const COINS = {btc: 'BTC', eth: 'ETH'}
export const OPERATIONS = {auto: 'AUTO', manual: 'MANUAL'};
export const ADMIN_ROLES = {admin: 'ADMIN', subAdmin: 'SUB_ADMIN'}
export const INDICATORS = {rsi: 'RSI', trailing: 'TRAILING'}
export const EXCHANGES = {binance: 'Binance', kuCoin: 'kuCoin'}
export const DAILY_PROFIT_DAYS = {THIRTY_DAYS: 30, NINETY_DAYS: 90};
export const STAGES = {LOW: 'Q1', MODERATE: 'Q2', HIGH: 'Q3', DEFAULT: '?'}
export const TOTAL_PROFIT_DAYS = {SEVEN_DAYS: 7, THIRTY_DAYS: 30, NINETY_DAYS: 90}
export const CONFIGURED_BY = {AUTOMATIC: 'Not Configured', SUB_ADMIN: 'By Sub Admin', ADMIN: 'Admin'}
export const SOCKET_EVENTS = {HIT_BINANCE_API: "HIT_BINANCE_API", SEND_BINANCE_API_DATA: "SEND_BINANCE_API_DATA"}
export const PROFIT_LOSS_DAYS = {DAILY: 'Daily', WEEKLY: 'Weekly', FIFTEEN_DAYS: '15 Days', THIRTY_DAYS: '30 Days'};

export const ADMIN_ROUTES = [
    '/',
    '/activity',
    '/portfolio',
    '/profit-loss',
    '/coin-market',
    '/market',
    '/auto-rsi',
    '/auto-trailing',
    '/manual-bot',
    '/user-management',
    '/satistics',
    '/pl-account',
    '/user-assign-management'
];

export const USER_ROUTES = [
    '/user/dashboard',
    '/user/paid-history',
    '/user/pl-account',
    '/user/satistics',
    '/user/prediction',
    '/user/bot-config',
    '/user-profit',
    '/user/api-setting',
    '/user/market',
]

export const TIMES_DROPDOWN = [
    {
        header: 'Minutes',
        items: ['1 minute', '3 minutes', '5 minutes', '15 minutes', '30 minutes', '45 minutes']
    },
    {
        header: 'Hours',
        items: ['1 hour', '2 hours', '3 hours', '4 hours']
    },
    {
        header: 'Days',
        items: ['1 day', '1 week', '1 month']
    }
]

export const LOCAL_SERVER = 'http://localhost:5000';
export const LIVE_SERVER = 'https://cryptobot-backend.herokuapp.com'