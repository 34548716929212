import {useState} from "react"
import {Dropdown, Form} from 'react-bootstrap'
import {AddUser, Loader,AssignUserSubAdmin} from "../components"
import {apis} from "../services"
import {useQuery} from "react-query";
import {isAdmin} from "../utils/user"
import { useMutation } from "react-query";
import { toast } from "react-toastify";

function UserManagement  () {

    const {data, isLoading, error, isFetching, refetch} = useQuery(['getAllUsers'], () => apis.allUsers())
    const users = data?.data || [];
    let admin = isAdmin();
 
    const [userAssign, setUserAssign] = useState({ user_id : ''});

    const [showAdmin, setShowAdmin] = useState(false);
    const handleCloseAdmin = () => setShowAdmin(false);
    const handleShowAdmin = () => setShowAdmin(true);
  
  
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const assignUserSubAdmin = (id) => {
        setUserAssign({user_id : id})
        handleShowAdmin();
    } 
    return <>
        <div className="dashboard-main custom-scroll">
            <div className="section">
                <h3 className="section-title">Admin Profile</h3>
                <div className="head-main">
                    <div className="head-section">
                        <div>
                            <h2 className="primary-head">User Management</h2>
                        </div>
                        {admin ? 
                        <div>
                            <button className="custom-btn secondary-btn" onClick={handleShow}>+ Add New User</button>
                        </div> : <></>}
                        
                    </div>
                </div>
                {isLoading ? <Loader variant="light"/>
                    : <>
                        <div className="table-responsive custom-scroll mt-5">
                            {users?.length > 0 ?
                                <>
                                    <table className="table custom-table">
                                        <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>User Name</th>
                                            <th>Email</th>
                                            {/*<th>Bot Status</th>*/}
                                            <th>Earnings</th>
                                            <th>User Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {users.map((item, i) => (
                                            <>

                                                <tr key={item?._id}>
                                                    <td>{i + 1}</td>
                                                    <td>{item?.name}</td>
                                                    <td>{item?.email}</td>
                                                    {/*<td>
                                                        <Form.Check
                                                            className="custom-switch"
                                                            type="switch"
                                                            id="custom-switch"
                                                            label=""
                                                        />
                                                    </td>*/}
                                                    <td>0</td>
                                                    <td>
                                                        <Dropdown className="boot-custom-dropdown">
                                                            <Dropdown.Toggle id="dropdown-basic">
                                                                <i className="fa-solid fa-ellipsis"></i>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>  
                                                                <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
                                                                <Dropdown.Item
                                                                    href="#/action-2">Restrict</Dropdown.Item>
                                                                <Dropdown.Item href="#/action-3">Input API
                                                                    Credentials</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>

                                            </>
                                        ))}
                                        </tbody>
                                    </table>
                                </>
                                :
                                <>
                                    <div className="custom-user">

                                        <p>
                                            No User.
                                        </p>
                                    </div>
                                </>}
                        </div>

                    </>}

            </div>


            <AddUser refetch={refetch} show={show} handleClose={handleClose}/>            
            {/* <AssignUserSubAdmin showAdmin={showAdmin} handleCloseAdmin={handleCloseAdmin} user_id = {userAssign} /> */}

        </div>
    </>
}

export default UserManagement
