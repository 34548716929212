import Joi from 'joi'
import {INDICATORS, OPERATIONS} from "../constants";

const setupBotValidation = (data) => {
    const schema = Joi.object({
        risk: Joi.string().required(),
        low: Joi.number().positive().required(),
        up: Joi.number().positive().required(),
        investment: Joi.number().positive().required(),
        indicator: Joi.string().valid(...Object.values(INDICATORS)).required(),
        operation: Joi.string().valid(...Object.values(OPERATIONS)).required(),
        time: Joi.any()
            .when('operation', {is: OPERATIONS.manual, then: Joi.string().allow(null)})
            .when('operation', {is: OPERATIONS.auto, then: Joi.string().not(null).required()}),
        configured_by: Joi.string().required(),
        isActive: Joi.bool().required(),
        coin: Joi.string(),
        takeProfit: Joi.number()
    });

    return schema.validate(data);
}

export default setupBotValidation