import {Dropdown, Form} from "react-bootstrap";
import _ from "lodash"
import {STAGES} from "../../constants";

const PortfolioDetails = (props) => {
    const id = _.get(props, 'id', 0),
        rsi = _.get(props, 'rsi', 0),
        balance = _.get(props, 'balance', 0),
        stop_at = _.get(props, 'stop_at', 0),
        trailing = _.get(props, 'trailing', 0),
        name = _.get(props, 'user.name', ''),
        investment = _.get(props, 'investment', 0),
        stage = _.get(props, 'risk', 'DEFAULT'),
        isActive = _.get(props,'isActive', false);

    const handleEdit = () => { /*alert('Handle Edit Option Triggered') */ }
    const handleRestrict = () => { /*alert('Restrict Option Triggered') */ }
    const handleInputAPiCredentials = () => { /*alert('Input API Credentials Option Triggered') */ }

    return (
        <tr>
            <td>{id}</td>
            <td>{name}</td>
            <td>
                <Form.Check
                    className="custom-switch"
                    type="switch"
                    id="custom-switch"
                    label=""
                    checked={!isActive}
                    readOnly={true}
                />
            </td>
            <td>${investment}</td>
            <td>${rsi}</td>
            <td>${trailing}</td>
            <td>${balance}</td>
            <td>{STAGES[stage]}</td>
            <td>{stop_at}</td>
            <td>
                <Dropdown className="boot-custom-dropdown">
                    <Dropdown.Toggle id="dropdown-basic">
                        <i className="fa-solid fa-ellipsis"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={handleEdit}>Edit</Dropdown.Item>
                        <Dropdown.Item onClick={handleRestrict}>Restrict</Dropdown.Item>
                        <Dropdown.Item onClick={handleInputAPiCredentials}>Input API Credentials</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </td>
        </tr>
    )
};

export default PortfolioDetails;