import {useEffect, useState} from "react"
import {Col, Container, Row} from 'react-bootstrap'
import TradingViewWidget from 'react-tradingview-widget';
import {
    AssetAllocation,
    BotProfitPieChart,
    CoinBox,
    DailyProfit,
    ProfitDistribution,
    TotalProfit,
    Winrate
} from "components";
import {apis} from "../services"
import {useQuery} from "react-query";
import {useSelector} from "react-redux";
import {COINS, DAILY_PROFIT_DAYS, EXCHANGES, TOTAL_PROFIT_DAYS} from "../constants";
import _ from "lodash";
import textColorClass from "../utils/textColorClass";

function Dashboard() {

    const {data: coins} = useSelector(store => store.binance);
    const {
        data: adminData,
        refetch
    } = useQuery(['getAllProfitAndLossAdmin'], () => apis.profitLossAccountDetailsAdmin(tab))
    const record = adminData?.data || [];
    const [tab, setTab] = useState(EXCHANGES.binance)
    const [coin, setCoin] = useState(COINS.btc) // ['BTC', 'ETH']
    const [profitDays, setProfitDays] = useState({totalProfitDays: 7, dailyProfitDays: 30});


    const profitDaysHandler = (key, value) => setProfitDays(prevState => ({...prevState, [key]: value}));
    const onChangeCoinHandler = (coin) => setCoin(coin);

    useEffect(() => {
        refetch()
    }, [tab,refetch]);

    console.log(record?.dailyProfit)

    return <>
        <div className="dashboard-main custom-scroll">
            <div className="section">
                <Container fluid>
                    <div className="bot-tabs mt-3 mb-5">
                        <ul className="justify-content-start">
                            <li>
                                <a className={tab === 'Binance' && "active"} href={'#!'}
                                   onClick={() => setTab(EXCHANGES.binance)}>Binance</a>
                            </li>
                            <li>
                                <a className={tab === 'kuCoin' && "active"} href={'#!'}
                                   onClick={() => setTab(EXCHANGES.kuCoin)}>KuCoin</a>
                            </li>
                        </ul>
                    </div>
                    <Row className="gy-5">
                        {
                            Object.values(coins).length > 0
                                ? Object.values(coins).map((_coin) => {
                                    console.log(_coin);
                                    const symbol = _coin.symbol?.toUpperCase();
                                    const change = _coin.change;
                                    const active_class = symbol === coin
                                        ? "box-active"
                                        : ''
                                    const change_class = change > 0
                                        ? `fa-caret-up text-green`
                                        : `fa-caret-down text-red`
                                    return <CoinBox
                                        {..._coin}
                                        active_class={active_class}
                                        change_class={change_class}
                                        onChange={onChangeCoinHandler}
                                        symbol={symbol}
                                    />
                                })
                                : null
                        }
                    </Row>
                    <div className="mt-5 mb-4">
                        <TradingViewWidget
                            width="100%"
                            height="441"
                            symbol={coin === COINS.btc ? "BITSTAMP:BTCUSDT" : "BITSTAMP:ETHUSDT"}
                            interval="D"
                            timezone="Etc/UTC"
                            theme="dark"
                            // style="1"
                            locale="en"
                            toolbar_bg="rgb(22, 26, 30)"
                            enable_publishing={false}
                            hide_side_toolbar={false}
                            allow_symbol_change={true}
                            hotlist={false}
                        />
                    </div>
                    <Row className="gy-3 mt-1">
                        <Col lg={12}>
                            <div className="normal-box">
                                <h3>Total Running Bot</h3>
                                <div className="inner-main">
                                    <div className="inner">
                                        <p className="m-0">Total Profit USDT</p>
                                        <span className={`big-font ${textColorClass(record?.totalProfitPrice)}`}>
                                            {record?.totalProfitPrice}
                                        </span>
                                    </div>
                                    <div className="inner">
                                        <p>Running Assets USDT</p>
                                        <span className="">{record?.runningAssets}</span>
                                    </div>
                                    <div className="inner">
                                        <p>Today’s Profit USDT</p>
                                        <span className={textColorClass(record?.todayProfitPrice)}>{record?.todayProfitPrice}</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="normal-box mt-4">
                                <div className="chart-filter">
                                    <ul className="ul">
                                        {_.values(TOTAL_PROFIT_DAYS).map((days, index) =>
                                            <li key={index}>
                                                <a href={"#!"}
                                                   className={days === profitDays.totalProfitDays ? 'active' : ''}
                                                   onClick={() => profitDaysHandler('totalProfitDays', days)}
                                                >
                                                    {days} Days</a>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                                <div className='flex-text-between'>
                                    <h3>Total Profit</h3>
                                    <a href={'#!'} className='gray-anchor'>Updated each hour</a>
                                </div>
                                <div className="chart-main">
                                    <TotalProfit days={profitDays.totalProfitDays} data={record?.totalProfit}/>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="normal-box mt-4">
                                <div className="chart-filter">
                                    <ul className="ul">
                                        {_.values(DAILY_PROFIT_DAYS).map((days, index) =>
                                            <li key={index}>
                                                <a href={"#!"}
                                                   className={days === profitDays.dailyProfitDays ? 'active' : ''}
                                                   onClick={() => profitDaysHandler('dailyProfitDays', days)}
                                                >
                                                    {days} Days</a>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                                <div className='flex-text-between'>
                                    <h3>Daily Profit</h3>
                                    <a href={'#!'} className='gray-anchor'>Updated on real time</a>
                                </div>
                                <div className="chart-main">
                                    <DailyProfit days={profitDays.dailyProfitDays} data={record?.dailyProfit}/>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="normal-box mt-4">

                                <div className='flex-text-between'>
                                    <h3>Profit Distribution</h3>
                                    <a href={'#!'} className='gray-anchor'>Updated on real time</a>
                                </div>
                                <div className="chart-main">
                                    <ProfitDistribution/>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="normal-box mt-4">

                                <div className='flex-text-between'>
                                    <h3>Winrate</h3>
                                    <a href={'#!'} className='gray-anchor'>Updated each hour</a>
                                </div>
                                <div className="chart-main">
                                    {/* <img src={imageURL('winrate.png')} className="chart-img"/> */}
                                    <Winrate data={record?.winrate}/>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="normal-box mt-4">

                                <div className='flex-text-between'>
                                    <h3>Asset Allocation</h3>
                                    <a href={'#!'} className='gray-anchor'>Details</a>
                                </div>
                                <div className="chart-main">
                                    <AssetAllocation/>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="normal-box mt-4">
                                <BotProfitPieChart/>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="mt-5">

                </div>
            </div>
        </div>
    </>
}

export default Dashboard
