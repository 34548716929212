import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Form } from "react-bootstrap";
import { useMutation } from "react-query";
import { apis } from "../../services"
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Table from 'react-bootstrap/Table';
import { useQuery } from "react-query";

export default function AssignUserSubAdmin({ showAdmin, handleCloseAdmin, sub_admin_id, user_id }) {

  const { data, isLoading: isLoadingUser, error, isFetching, refetch } = useQuery(['getAllUsers'], () => apis.allUsers())
  let users = data?.data || [];

  const { isLoading, mutate } = useMutation(apis.userAssignAdmin, {
    onError: error => {
      if (typeof error.message === 'string')
        toast.error(error.message);
      else
        error.message.map(err => toast.error(err))

      // console.error('ERROR IN REGISTRATION', error)
    },
    onSuccess: ({ data: user, headers, status }) => {
      if (status === 200) {
        // const token = headers['x-auth-token'];

        toast.success('Assign User To Sub Admin Successful');
      }
    }
  })

  const [selected, setSelected] = useState(user_id);

  useEffect(() => {
    setSelected(user_id);
  }, [user_id])

 

  const onSubmitHandler = (e) => {
    let data = { user_id: selected, sub_admin_id: sub_admin_id }
    console.log("DataSubmit", data);
    mutate(data);
  };

  const onSelectHandler = (id) => {
    const currentIndex = selected.indexOf(id);
    if (currentIndex === -1) {
      selected.push(id);
      setSelected([...selected]);
    } else {
      setSelected(selected.filter((_id) => _id !== id));
    }
  };

  return (
    <>
      <Modal show={showAdmin} onHide={handleCloseAdmin} centered className='custom-modal'>
        <Modal.Header>
          <Modal.Title>Assign User To SubAdmin</Modal.Title>
          <div className="close-btn" onClick={handleCloseAdmin}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmitHandler}>
            <div style={{overflow: "auto" , maxHeight: "400px"}}>

            <Table responsive bordered hover size="sm" variant="dark">
              <thead>
                <tr>
                  <th style={{ border: "1px solid grey", textAlign: 'center' }}>Name</th>
                  <th style={{ border: "1px solid grey", textAlign: 'center' }}>Email</th>
                  <th style={{ border: "1px solid grey", textAlign: 'center' }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {users.length > 0 ? (
                  users.map((item, i) => {
                    return (
                      <>
                        <tr key={item.id}>
                          <td style={{ textAlign: 'center' }} >{item?.name}</td>

                          <td style={{ textAlign: 'center' }} >{item?.email}</td>
                          <td style={{ textAlign: 'center' }}><input type="checkbox" checked={selected.includes(item._id)} onClick={() => onSelectHandler(item?._id)}></input></td>
                        </tr>
                      </>
                    )
                  })
                ) : (
                  <tr style={{ textAlign: 'center' }} >
                    <td colSpan={7}>No Sub Admin</td>
                  </tr>
                )}

              </tbody>
            </Table>
            
            </div>
            <Form.Group className="mt-5 text-center">
              {isLoading ?
                <>
                  <button type="submit" disabled className="custom-btn primary-btn round-btn">Loading</button>
                </> :
                <>
                  <button type="submit" className="custom-btn primary-btn round-btn">Done</button>
                </>}
            </Form.Group>
          </Form>
        </Modal.Body>

      </Modal>
    </>
  );
}
