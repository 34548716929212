import {Col, Row} from "react-bootstrap";
import {getEstimatedTime, profitPercentage} from "utils";
import {STAGES} from "../constants";

const Order = (props) => {
    const {coin, createdAt, day, profit, loss, risk, isActive, investment, setting} = props;

    const totalProfit = profit || loss;
    const percentage = profitPercentage(profit, loss, investment)
    const {days, minutes, hours} = getEstimatedTime(createdAt);

    const renderTime = () => `
    ${days && `${days}D`}
    ${minutes && `${hours}H`}
    ${minutes && `${minutes}M`}
    `

    return (
        <Row className="gy-3">
            <Col className="col-12">
                <div className="main-statistics">
                    <div className="padding-normal-l-r-t-b">
                        <p className="text-gray f-18 mb-0"> {STAGES[risk]}</p>
                        <h3>{coin}/USDT</h3>
                        <div className="mb-2">
                            <i className={`fa-solid fa-circle text-${isActive ? 'green' : 'red'}`}></i>
                            <span className="text-gray"> {renderTime()}</span>
                        </div>
                        {/*<p className="primary-text f-18 mb-0">0 arbitrages in 24 hours/Total 0 arbitrages</p>*/}
                    </div>
                    <div className="other-bg">
                        <div className="invest-profit">
                            <div className="inner">
                                <p className="gray-text f-18 mb-0">Investment USDT</p>
                                <h2>{investment}</h2>
                            </div>
                            <div className="inner">
                                <p className="gray-text f-18 mb-0">Total Profit USDT</p>
                                <h2 className={`text-${totalProfit > 0 ? 'green' : 'red'} red text-center`}>{totalProfit} <sub>{percentage}%</sub></h2>
                            </div>
                        </div>
                    </div>
                    {
                        !isActive &&
                        <ul className="statistic-list">
                            <li>
                                <span>Price Range (USDT)</span>
                                <span>{ setting?.low || 0 } - {setting.up || 0}</span>
                            </li>
                            <li>
                                <span>No. of Days Orders</span>
                                <span>{day}</span>
                            </li>
                            <li>
                                <span>Profit/Grid Q1</span>
                                {/*<span>0%-0%</span>*/}
                            </li>
                        </ul>
                    }
                </div>
            </Col>
        </Row>
    )
};

export default Order;
