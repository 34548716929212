import {useState} from "react"
import {Dropdown, Form} from 'react-bootstrap'
import {AddUser, Loader,AssignUserSubAdmin} from "../components"
import {apis} from "../services"
import {useQuery} from "react-query";
import {isAdmin} from "../utils/user"
import { useMutation } from "react-query";
import { toast } from "react-toastify";

function UserAssignManagement  () {

    const {data, isLoading, error, isFetching, refetch} = useQuery(['getAllSubAdmin'], () => apis.allSubAdmin())
    const users = data?.data || [];
 
    let admin = isAdmin();
 
    const [userAssign, setUserAssign] = useState({ sub_admin_id : '' , user_id : []});

    const [showAdmin, setShowAdmin] = useState(false);
    const handleCloseAdmin = () => setShowAdmin(false);
    const handleShowAdmin = () => setShowAdmin(true);
  
    const assignUserSubAdmin = (id,userId) => {
        console.log("ADMIN Id",id);  
        console.log("USER Id",userId);
        setUserAssign({sub_admin_id : id , user_id : userId })
            handleShowAdmin();    
    } 
    return <>
        <div className="dashboard-main custom-scroll">
            <div className="section">
                <h3 className="section-title">Admin Profile</h3>
                <div className="head-main">
                    <div className="head-section">
                        <div>
                            <h2 className="primary-head">User Assign Management</h2>
                        </div>
                        {/* {admin ? 
                        <div>
                            <button className="custom-btn secondary-btn" onClick={handleShow}>+ Add New User</button>
                        </div> : <></>}
                         */}
                    </div>
                </div>
                {isLoading ? <Loader variant="light"/>
                    : <>
                        <div className="table-responsive custom-scroll mt-5">
                            {users?.length > 0 ?
                                <>
                                    <table className="table custom-table">
                                        <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Sub Admin Name</th>
                                            <th>Email</th>
                                            <th>Total Users Assign</th>
                                            <th>User Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {users.map((item, i) => (
                                            <>
                                                <tr key={item?._id}>
                                                    <td>{i + 1}</td>
                                                    <td>{item?.name}</td>
                                                    <td>{item?.email}</td>
                                                    <td>{item?.users_info}</td>
                                                    <td>
                                                        <Dropdown className="boot-custom-dropdown">
                                                            <Dropdown.Toggle id="dropdown-basic">
                                                                <i className="fa-solid fa-ellipsis"></i>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                            <Dropdown.Item onClick={()=>assignUserSubAdmin(item?._id,item?.users_id)}>Assign to sub admin</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            </>
                                        ))}
                                        </tbody>
                                    </table>
                                </>
                                :
                                <>
                                    <div className="custom-user">

                                        <p>
                                            No Sub Admin.
                                        </p>
                                    </div>
                                </>}
                        </div>

                    </>}

            </div>

        <AssignUserSubAdmin showAdmin={showAdmin} handleCloseAdmin={handleCloseAdmin} user_id = {userAssign?.user_id} sub_admin_id={userAssign?.sub_admin_id} />

        </div>
    </>
}

export default UserAssignManagement
