import {useState} from "react"
import {imageURL} from "../hooks"
import {AddUser, Loader, PortfolioTab} from "../components"
import {useQuery} from "react-query";
import {apis} from "../services";
import {toast} from "react-toastify";
import _ from "lodash";
import {PortfolioDetails} from "../components/admin";
import {useSelector} from "react-redux";

function Portfolio() {
    const {balance} = useSelector(store => store.binance);

    const {data, isLoading} = useQuery(['users_portfolio'], apis.usersPortfolio, {
        onError: error => toast.error(error),
        onSuccess: ({data, status}) => {
            console.log({data, status});
        }
    });

    //Destructuring
    const users = _.get(data, 'data', []);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return <>
        <div className="dashboard-main custom-scroll">
            <div className="section">
                <div className="head-main">
                    <div className="head-section">
                        <div className="balance-section">
                            <span>Total Balance</span>
                            <div className="coin-detail">
                                <img src={imageURL('binance.png')} alt="Binance"/>
                                <h3>{balance}</h3>
                            </div>
                        </div>
                        <div>
                            <button className="custom-btn secondary-btn" onClick={handleShow}>+ Add New User</button>
                        </div>
                    </div>
                </div>
                <PortfolioTab/>
                <div className="table-responsive custom-scroll mt-5 min-vh-50">
                    {
                        isLoading
                            ? <Loader variant='light' style={{ minHeight: '50vh' }}/>
                            : <table className="table custom-table">
                                <thead>
                                <tr>
                                    <th>User ID</th>
                                    <th>User Name</th>
                                    <th>Bot Status</th>
                                    <th>Total Investment</th>
                                    <th>RSI</th>
                                    <th>Trailing</th>
                                    <th>In Bucket</th>
                                    <th>Stage Selected</th>
                                    <th>Stop Loser</th>
                                    <th>User Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    users.map((user, index) =>
                                        <PortfolioDetails key={index} {...user} id={index + 1}/>)
                                }
                                </tbody>
                            </table>
                    }
                </div>
            </div>
            <AddUser show={show} handleClose={handleClose}/>
        </div>
    </>
}

export default Portfolio
