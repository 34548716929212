import _ from "lodash";
import {useState} from "react"
import {toast} from "react-toastify";
import {useQuery} from "react-query";
import {useDispatch} from "react-redux";
import {Container} from 'react-bootstrap'

import {apis} from "services";
import {Order, Loader} from "components"
import {logout} from "redux/slices/user.slice";

function UserStatistics() {
    const [tab, setTab] = useState('open');
    const dispatch = useDispatch();

    const {data, isFetching, isLoading} = useQuery([tab], apis.userOrdersBot, {
        onError: ({message, response: {status}}) => {
            toast.error(message)
            if (status === 401) {
                dispatch(logout())
            }

        },
        onSuccess: ({status, data}) => {
            // console.log({status, data});
        }
    });

    const loading = isLoading || isFetching;
    // Destructuring
    const orders = _.get(data, 'data', []);

    return <>
        <div className="dashboard-main custom-scroll">
            <div className="section">
                <Container fluid>
                    <div className="profit-loss-tab mb-5">
                        <ul className="justify-content-start">
                            <li>
                                <a href={'#'} className={tab === "open" && "active"}
                                   onClick={() => setTab('open')}>Open Orders</a>
                            </li>
                            <li>
                                <a href={'#'} className={tab === "close" && "active"}
                                   onClick={() => setTab('close')}>Close Orders</a>
                            </li>
                        </ul>
                    </div>
                    {
                        loading
                            ? <Loader variant={'light'}/>
                            : orders.length > 0
                                ? orders.map((order, indx) => <Order key={indx} {...order}/>)
                                :
                                <div className='d-flex justify-content-center align-items-center' style={{height: '50vh'}}>
                                    <h4>no {tab} orders found</h4>
                                </div>
                    }
                    {/*<Questions type={tab}/>*/}
                </Container>
                <div className="mt-5">

                </div>
            </div>
        </div>
    </>
}

export default UserStatistics
