import {useState} from "react"
import _ from "lodash";
import {apis} from "services";
import {useQuery} from "react-query";
import {Col, Container, Row} from 'react-bootstrap'
import {PaidHistoryPieChart, PaidHistoryTabs} from "components";
import {COINS} from "../../constants";

function PaidHistory() {
    const [tab, setTab] = useState(COINS.eth);
    const {data: response} = useQuery([tab], apis.paidHistory, {
        onError: error => console.log(error),
        /*onSuccess: ({data,status}) => {
            console.log({status,data})
        }*/
    })

    const data = _.get(response, 'data', []);

    return <>
        <div className="dashboard-main custom-scroll">
            <div className="section">
                <Container fluid>
                    <PaidHistoryTabs tab={tab} setTab={setTab}/>
                    <Row className="gy-3">
                        <Col lg={12}>
                            <div className="normal-box">
                                <PaidHistoryPieChart data={data?.chart}/>
                            </div>
                            <div className="profit-lost-list">
                                <div className="inner">
                                    <h3>{data?.chart?.paid || 0}</h3>
                                    <span>Amount Paid:</span>
                                </div>
                                <div className="inner">
                                    <h3>{data?.chart?.unPaid || 0}</h3>
                                    <span>Amount Unpaid:</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {/*<Questions limit={1}/>*/}
                </Container>

            </div>
        </div>
    </>
}

export default PaidHistory