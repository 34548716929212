import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Form } from "react-bootstrap";
import { useMutation } from "react-query";
import { apis } from "../../services"
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function AddUser({ show, handleClose , refetch }) {

  const { isLoading, mutate } = useMutation(apis.registration, {
    onError: error => {
      if (typeof error.message === 'string')
        toast.error(error.message);
      else
        error.message.map(err => toast.error(err))

      // console.error('ERROR IN REGISTRATION', error)
    },
    onSuccess: ({ data: user, headers, status }) => {
      if (status === 201) {
        // const token = headers['x-auth-token'];
        toast.success('Added Successful');
        refetch();
        handleClose();

      }
    }
  });
  const [data, setData] = useState({ role : '', name: '', email: '', password: '' });

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setData(prevData => ({ ...prevData, [name]: value }));
    console.log(data);
  };
console.log(data);
  const onSubmitHandler = (e) => {
    e.preventDefault();
    mutate(data);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered className='custom-modal'>
        <Modal.Header>
          <Modal.Title>Add User</Modal.Title>
          <div className="close-btn" onClick={handleClose}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmitHandler}>
            <Form.Group className="mb-3" controlId="name">
              <Form.Control name='name' onChange={onChangeHandler} type="text" className='custom-input' placeholder="Input User Name" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
              <Form.Control name="email" onChange={onChangeHandler} type="email" className='custom-input' placeholder="Email Address" />
            </Form.Group>
            <Form.Group onChange={onChangeHandler} className="mb-3" controlId="password">
              <Form.Control name="password" type="password" className='custom-input' placeholder="Password" />
            </Form.Group>
            <Form.Group onChange={onChangeHandler} className="mb-3" controlId="password">
              <Form.Select onChange={onChangeHandler} required className='custom-input' aria-label="Default select example" name = "role" id="role">
                <option >Select Role </option>
                <option >SUB_ADMIN</option>
                <option >USER</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mt-5 text-center">
              {isLoading ? 
              <>
              <button type="submit"  disabled className="custom-btn primary-btn round-btn">Loading</button>
              </> : 
              <>
               <button type="submit" className="custom-btn primary-btn round-btn">Done</button>
              </>}
            </Form.Group>
          </Form>
        </Modal.Body>

      </Modal>
    </>
  );
}
